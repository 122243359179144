import React from 'react'
import PropTypes from 'prop-types'
import { Grid } from '@mui/material'
import { CustomBtn } from '../../../../components/button/button'
import { PostRequest } from '../../../../redux/actions/PostRequest'
import { useDispatch, useSelector } from 'react-redux'

const Annoucement = props => {
    const dispatch = useDispatch()
    const url = useSelector((state)=>state.Api)
    const fee = () =>{
        const confirmed = window.confirm("Do you want to Announce Fees");

        // If user confirms, dispatch the request
        if (confirmed) {
            dispatch(PostRequest(url.annnounceFees,JSON.parse(sessionStorage.getItem("User_Data")).token))
        } else {
            console.log("Fee announcement cancelled by user.");
        }
    }
    const salary = () =>{
        const confirmed = window.confirm("Do you want to Announce Salary");

        // If user confirms, dispatch the request
        if (confirmed) {
            dispatch(PostRequest(url.annnounceSalary,JSON.parse(sessionStorage.getItem("User_Data")).token))
        } else {
            console.log("Salary announcement cancelled by user.");
        }
    }
  return (
        <Grid container>
            <Grid item lg={12}>
                    <h3>Please remember to keep announcing Fees and Salary regularly  every month on selected date only once.</h3>
                    <h2></h2>
                    <br/>
            </Grid>
            <Grid item lg={4}>
                <CustomBtn data="Announcement Of Fee  " click={fee} />
            </Grid>
            <Grid item lg={2}></Grid>
            <Grid item lg={4}>
                <CustomBtn data="Announcement Of Salary " click={salary} />
            </Grid>
        </Grid>
  )
}

Annoucement.propTypes = {}

export default Annoucement