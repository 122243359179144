import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import { Input } from '../../../../components/input/input';
import { CustomBtn } from '../../../../components/button/button';
import axios from 'axios';
// import {  useSelector } from 'react-redux';
import { editSbuject } from '../../../../redux/actions/subject/editsubject';
import { deleteSubject } from '../../../../redux/actions/subject/deletesubject';

const AddSubjectform = (props) => {
    console.log(props.currentClass)
    const [inputValues, setInputValues] = useState({Srn:props.currentClass});
    const [ sub , setSub ] = useState('');
    // const url = useSelector((state)=>state.Api)
    // const dispatch = useDispatch()
    const handleInputChange = (e) => {
        // console.log(e.target.value)
        setInputValues((prevValues) => ({
            ...prevValues,
            [e.target.name]: e.target.value,
        }));
    };

    const myfunc = (event) => {
        event.preventDefault();
        console.log(inputValues);
        if(sub == ''){
            props.AddNewSubject(inputValues)
            setSub('true')

        }
        
    };
    const addsub = () =>{
        props.AddNewSubject(inputValues)
        setSub('true')
    }
    return (
        <>
            <form onSubmit={myfunc}>
                <Grid container mt={2}>
                    <Grid item lg={2}>
                        <Input
                            onChange={(e) => handleInputChange(e)}
                            name="SubjectName"
                            value={inputValues.SubjectName}
                            placeholder="Subject Name"
                            required
                        />
                    </Grid>
                    <Grid item lg={2}>
                        <Input
                            onChange={(e) => handleInputChange(e)}
                            name="SubjectNamePashot"
                            value={inputValues.SubjectNamePashot}
                            placeholder="Subject Pashto Name"
                            required

                        />
                    </Grid>
                    <Grid item lg={2}>
                        <Input
                            type="number"
                            onChange={(e) => handleInputChange(e)}
                            name="Half_Year_Passing"
                            value={inputValues.Half_Year_Passing}
                            placeholder="Half Year Passing"
                            required
                        />
                    </Grid>
                    <Grid item lg={2}>
                        <Input
                            type="number"
                            onChange={(e) => handleInputChange(e)}
                            name="Full_Year_Passing"
                            value={inputValues.Full_Year_Passing}
                            placeholder="Full Year Passing"
                            required
                        />
                    </Grid>
                    <Grid item lg={2}>
                        <Input
                            type="number"
                            onChange={(e) => handleInputChange(e)}
                            name="TotalMarks"
                            value={inputValues.TotalMarks}
                            placeholder="Total Marks"
                            required
                        />
                    </Grid>
                    {

                        sub == ''?
                        <Grid item lg={2}>
                            <CustomBtn
                                data="Add Sbuject"
                                click={addsub}
                            />
                    </Grid>
                            :
                            <>
                                <Grid item lg={1}>
                                    <CustomBtn
                                        data="Edit"
                                        click={()=>props.edit(inputValues)}
                                    />
                                </Grid>
                                <Grid item lg={1}>
                                    <CustomBtn 
                                    data="Delete" 
                                    click={()=>props.deletesub(inputValues)}
                                    />
                                </Grid>
                            </>
                    }
                </Grid>
            </form>
        </>
    );
};

AddSubjectform.propTypes = {};

export default AddSubjectform;
