import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import PageContainer from '../../../../components/Container/pageContainer'
import { Box, Grid, Tab, Tabs, Typography } from '@mui/material'
import CustomForm from '../../../../components/form/form'
import { Main } from '../../../../constant'
import { CustomBtn } from '../../../../components/button/button'
import SuggestionInput from '../../../../components/suggestionInput/suggestionInput'
import { Date } from '../../../../components/Date/Date'
import dayjs from 'dayjs'
import CustomTable from '../../../../components/table/SimpleTable'
import { Input } from '../../../../components/input/input'
import { useDispatch, useSelector } from 'react-redux'
import axios from 'axios'
import { FullDate } from '../../../../components/Date/FullDate'
import { PostRequest } from '../../../../redux/actions/PostRequest'
import { CustomSelect } from '../../../../components/select/select'
import StudentReportComponent from '../../../../components/report/student'
import { ShowLoader } from '../../../../redux/actions/loader'
import { TabPanel, TabContext } from '@mui/lab';
import ShowClass from '../../../Dashboard/Attendance/studentAttendance/components/showClass';

const Fee = (props) => {
    const [data , setData] = useState(Main().Fee);
    const [getfilterdata , setGetfilterdata ] = useState(data.inputs.filter((item) => item.feildtype !== 'label'));
    const [initialInputValues , setInitialInputValues] = useState(Object.fromEntries(
        getfilterdata
            .map((item) => [item.name, ''])
    ));
    const [currentTab, setCurrentTab] = useState(0); // State variable to control the current tab
    const [suggestions , setSuggestions] = useState();
    const dispatch = useDispatch();
    const userToken = JSON.parse(sessionStorage.getItem('User_Data'))?.token || undefined;
    const url = useSelector((state) => state.Api);
    const [update , setUpdate] = useState(0);
    const [ studentOldDetails , setStudentOldDetails ] = useState()
    const [maxSelectableDate , setMaxSelectableDate] = useState(); 
    const [tableData , setTableData ] = useState();
    const [defaulters , setDefaulters ] = useState();
    const [totalAmount , setTotalAmount ] = useState();
    const [totalStudents , setTotalStudents ] = useState();
    const [getClassName , setGetClassName ] = useState('');
    const showlatefee = useSelector((state) => state.Api.showlatefee);

    
    const handleChangeTab = (event, newValue) => {
      setCurrentTab(newValue);
    };


    const [inputValues, setInputValues] = useState({Date: dayjs().format('MMMM YYYY'), ...initialInputValues});

    const fetchstudent = (param) => {
        dispatch(ShowLoader('1'))
        axios.post(url.showoldFessDetails, param,{
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${userToken}`,
            },
          })
          .then((res) => {
            if (res.status === 200) {
              res.data.StudentDetails.feesPage=true
              setStudentOldDetails(res.data);
              setMaxSelectableDate(dayjs(res.data.lastrecord))
              // setTimeout(()=>{
                dispatch(ShowLoader('0'))
              // },1000)
            }
          })
          .catch((err) => {
          dispatch(ShowLoader('0'))
            console.error(err); // Log the error response for debugging
          });
    }
    function suggestionhandleInputChange(e) {
        setStudentOldDetails('')
        console.log(e.target.name,e.target.value)
        setInputValues((prevValues) => ({
            ...prevValues,
            [e.target.name]: e.target.value,
            Ammount: '',
        }));
        const RollNum = { RollNum: e.target.value}
        fetchstudent(RollNum)   
    }
    const ChangeDate = (e) =>{
        // console.log(`${e.$y}-${e.$M}-${e.$D}`)
        console.log( `${e.$y}-${e.$M+1}-${e.$D}`)
        setInputValues((prevValues) => ({
            ...prevValues,
            Month: `${e.$y}-${e.$M+1}-${e.$D}`,
          }));
          
    }
    const submitfunc = async (e) =>{
        e.preventDefault();
        const payload = {...inputValues}
        console.log(payload)
        await dispatch(await PostRequest(url.AddFess , userToken , payload))
        setUpdate((update)=>update+1)
        const RollNum = {RollNum : inputValues.RollNum}
        fetchstudent(RollNum)
    }
    useEffect(() => {
        try {
          axios.get(url.studentsuggestion, {
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${userToken}`,
            },
          })
          .then((res) => {
            if (res.status === 200) {
              console.log(res.data.message);
              setSuggestions(res.data.message);
            }
          })
          .catch((err) => {
            console.error(err); // Log the error response for debugging
          });
        } catch (err) {
          console.error(err);
        }
      }, [update]);

      const handleChange = (event) => {
        // console.log(event.target.value)
        setInputValues((prevValues) => ({
            ...prevValues,
        }));
        // props.GetSelectedValue([event.target.value, event.target.name]);
      };
      function handleInputChange(e) {
        setInputValues((prevValues) => ({
            ...prevValues,
            [e.target.name]: e.target.value,
            ['Discount']:studentOldDetails.FeesDetials.Discount
        }));
        console.log(inputValues)
    }

    const ClearDues = () =>{
        console.log('clicked')
        dispatch(PostRequest(url.clearFees , userToken , {RollNum : studentOldDetails.result[0].RollNum}))
        setUpdate((update)=>update+1)
        fetchstudent({RollNum : studentOldDetails.result[0].RollNum})   
    }

    const updatefunc = (getparam) => {
      const getFilteredDataGettingClassName = tableData.result.filter(item => {
        if(item.ClassID === getparam){
          setGetClassName(item.Class)
        }
      })
      const filteredData = tableData.result.filter(item => {
        return item.ClassID === getparam;
      }).map(filteredItem => {
        // Removing 'Picture', 'Class', and 'ClassID' properties from the filtered item
        const { Picture, Class, ClassID, ...rest } = filteredItem;
        return rest; 
      });

      const totalAmmount = filteredData.reduce((sum, item) => {
        return sum + item.Ammount;
      }, 0);
      // setGetClassName(getFilteredDataGettingClassName != undefined ? getFilteredDataGettingClassName[0].Class : '')
      setTotalAmount(totalAmmount)
      setTotalStudents(filteredData.length)
      setDefaulters(filteredData)
    }
    const fetchDefaulters = () => {
      console.log(tableData)
      if(!tableData || tableData == null || tableData == undefined ){      
      try {
          dispatch(ShowLoader('1'))
          axios.get(`${showlatefee}`, {
              headers: {
                  'Content-Type': 'application/json',
                  'Authorization': `Bearer ${userToken}`,
              },
          })
          .then((res) => {
              if (res.status === 200) {
                  dispatch(ShowLoader('0'))
                  console.log(res.data.result);
                  setTableData(res.data);
              }
          })
          .catch((err) => {
              console.error(err); // Log the error response for debugging
          });
      } catch (err) {
          console.error(err);
      }
    }
  }
    return (
      <TabContext value={currentTab.toString()}>
        <PageContainer title="Student Attendance">
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={currentTab} onChange={handleChangeTab} aria-label="basic tabs example">
              <Tab label="Fees "  value={0} />
              <Tab onClick={fetchDefaulters} label="Class Fees Overview " value={1} />
            </Tabs>
          </Box>
          <TabPanel value="0">
              <form onSubmit={submitfunc}>
                <Grid container>
                    <Grid item lg={6} md={9} sm={12} xs={12}>
                        <SuggestionInput 
                            handleInputChange={suggestionhandleInputChange}
                            Suggestions={suggestions}
                            placeholder='Roll No / Name'
                            name='RollNum'
                            />
                            {
                                studentOldDetails ? studentOldDetails.result && (
                                    <>
                                        <Grid container>
                                            <Grid item lg={6}>
                                                <Box mt={1}>
                                                    Monthly Fee : 
                                                    <Typography sx={{color:'green'}} variant='b'>
                                                      {studentOldDetails.FeesDetials.MonthlyFee}
                                                    </Typography>
                                                </Box>
                                            </Grid>
                                            <Grid item lg={6}>
                                                <Box mt={1}>
                                                    Discount : 
                                                    <Typography sx={{color:'green'}} variant='b'>
                                                      {studentOldDetails.FeesDetials.Discount}
                                                    </Typography>
                                                </Box>
                                            </Grid>
                                            <Grid item lg={6}>
                                                <Box mt={1}>
                                                    Dues : 
                                                    <Typography sx={{color:'red'}} variant='b'>
                                                        {studentOldDetails.FeesDetials.reduceamount}
                                                        {/* {studentOldDetails.status[0]['Extra Paid']} */}
                                                      </Typography>
                                                </Box>
                                            </Grid>
                                            <Grid item lg={6}>
                                                <Box mt={1}>
                                                    Extra Paid :
                                                    <Typography sx={{color:'green'}} variant='b'>
                                                        {studentOldDetails.status[0]['Extra Paid']}
                                                      </Typography>
                                                </Box>
                                            </Grid>
            
                                        </Grid>
                                        <Input sx={{margin:'15px 0px 0px'}} onChange={(event)=>handleInputChange(event)} name="Ammount" placeholder='Ammount' />
                                        <Grid container>
                                            {/* <Grid item lg={12}>
                                            {/* <Date sx={{width: '100%'}} /> */}

                                                {/* {
                                                    maxSelectableDate && (
                                                        <FullDate throwfullevent={ChangeDate} name="Month" min={maxSelectableDate} width='100%' max={maxSelectableDate} />
                                                        )
                                                    }
                                            </Grid> */} 
                                            {/* } */}

                                            
                                        </Grid>
                                        {/* <CustomSelect items={['jan','feb']} /> */}
                                        <Input sx={{margin:'15px 0px 0px'}} onChange={(event)=>handleInputChange(event)} name="comments" placeholder='Comments' />
                                        {/* <br /> */}
                                        <Box mt={1}></Box>
                                        <CustomBtn disable={inputValues.Ammount == '' || inputValues.Ammount == undefined} data='Submit' />
                                    </>
                                )
                                :
                                null
                            }
                    </Grid>
                    <Grid item lg={6} md={6} sm={12} xs={12}>
                      {
                        studentOldDetails && (
                          <StudentReportComponent data={studentOldDetails.StudentDetails} />

                        )
                      }
                    </Grid>
                </Grid>
            </form>
            <Grid container>
                <Grid item lg={9} mt={4}>
                    {
                        // studentOldDetails && studentOldDetails.result != [] && studentOldDetails.result != [] ? 
                        studentOldDetails && studentOldDetails.result.length != 0 && studentOldDetails.result != null && studentOldDetails.result != undefined  ?
                        <CustomTable data={studentOldDetails.result} />            
                        :
                        null
                    }
                </Grid> 
            </Grid>
          </TabPanel>
          <TabPanel value="1">
            {/* Content for Leaves tab */}
            <Box>
                <ShowClass func={updatefunc} highlightClass={update} />
            </Box>
            <Box>
            <Grid container>
                <Grid item lg={9} mt={4}>
                    {
                        defaulters && defaulters.length != 0 && defaulters != null && defaulters != undefined  ?
                        <>
                          <div className='defaultersStudents'>
                            {/* <span><b>{getClassName}</b></span><br/> */}
                            <span>Students: <b>{totalStudents}</b></span><br/>
                            <span>Total Amount<b>{totalAmount}</b></span>
                          </div>
                          <CustomTable data={defaulters} />
                        </>
                        :
                        null
                    }
                </Grid> 
            </Grid>
            </Box>
          </TabPanel>
        </PageContainer>
      </TabContext>
    );
}

Fee.propTypes = {}

export default Fee