import { UserData } from "../../hooks/FirstTimeWebSrn/Websrn";


const web = 'https://smartschool.host/';
// const web = 'http://localhost:4000/';

const initialState = {
    //website api's
    Login:`${web}login`,
    Web:`${web}WebSrn`,
    // DashBoard Api's
    studentCards: `${web}dashboard/studentCards`,
    teacherCards: `${web}dashboard/teacherCards`,
    MobileApp: `${web}dashboard/mobileAppRegister`,
    MobileAppList: `${web}dashboard/mobileAppRegData`,
    Showparents: `${web}dashboard/showparents`,
    showchildren:`${web}dashboard/showchildren/`,
    assignChildren: `${web}dashboard/assignChildren`,

    StudentRegistation: `${web}dashboard/studentReg`,
    TeacherRegistation: `${web}dashboard/teacherReg`,
    Imagelink: `${web}dashboard/createFolder`,
    // Class Api's
    CreateClass: `${web}dashboard/createClass`,
    ShowClass: `${web}dashboard/showclass`,
    showClassStudents: `${web}dashboard/showClassStudents/`,
    // Subjects 
    DeleteSubject: `${web}dashboard/deleteSubject`,
    ShowSubject: `${web}dashboard/ShowSubjects`,
    UpdateSubject: `${web}dashboard/UpdateSubject`,
    AddSubject: `${web}dashboard/addSubject`,
    //Student attendance api's
    ShortAttendance: `${web}dashboard/shortAttendance`,
    StuedentAttendence: `${web}dashboard/attendence`,
    Studentmaunaulattendence: `${web}dashboard/maunaulattendence`,
    Studentcurrentdayreport: `${web}dashboard/currentdayreport`,
    Studentshowattendence: `${web}dashboard/showattendence/`,
    //Teacher Attendance api's
    Teachermaunaulattendence: `${web}dashboard/Teacherattendence`,
    Teachercurrentdayreport:`${web}dashboard/showTeacherAttendence`,
    //Studnets suggestion api
    studentsuggestion: `${web}dashboard/studentsuggestion`,
    teachersuggestion: `${web}dashboard/teachersuggestion`,
    imageServer: `${web}public/`,
    showoldFessDetails:`${web}dashboard/showoldFessDetails`,
    showoldsalaryDetails: `${web}dashboard/showoldsalaryDetails`,
    // Customize Rolls
    webroles: `${web}dashboard/webRoles`,
    veiwroles: `${web}dashboard/veiwroles`,
    assignroles: `${web}dashboard/assignroles/`,
    //Private Messages
    privateMessage: `${web}dashboard/privateMessage`,
    veiwMessages: `${web}dashboard/veiwMessages`,
    //Group Messages
    Allmembersmessage: `${web}dashboard/Allmembersmessage`,
    veiwAllmemberMessages: `${web}dashboard/veiwAllmemberMessages`,
    //selected messages
    selectedmessage:`${web}dashboard/selectedmessages`,
    //annnouncmentholidays
    annnouncmentholidays: `${web}dashboard/annnouncmentholidays`,
    //public announcement
    publicannouncemnet: `${web}dashboard/publicannnouncment`,
    addpublicannouncemnet: `${web}dashboard/addpublicannnouncment`,
    switchpublicannouncemnet: `${web}dashboard/switch`,
    //Collection        //Fees
    AddFess: `${web}dashboard/AddFees`,
    clearFees: `${web}dashboard/clearFees`,
    annnounceFees: `${web}dashboard/annnounce`,
    //Salary
    AddSalary: `${web}dashboard/AddSalary`,
    clearSalary: `${web}dashboard/clearSalary`,
    annnounceSalary: `${web}dashboard/annnounceSalary`,
    //Report 
        //Teacher
    AddReportTeacher:`${web}dashboard/AddTeacherReport`,
    ShowReportTeacher:`${web}dashboard/showTeacherReport`,
    showSpecificTeacher: `${web}dashboard/showSpecificTeacher`,
        //Student
    AddReportStudent:`${web}dashboard/AddStudentReport`,
    ShowReportStudent:`${web}dashboard/showStudentReport`,
    showSpecificStudent: `${web}dashboard/showSpecificStudents`,

    // Shoqa
    showShoqa:`${web}dashboard/showShoqa`,
    addShoqa: `${web}dashboard/addShoqa`,
    checkShoqa: `${web}dashboard/checkShoqa`,
    //donation
    donation:`${web}dashboard/donation`,
    veiwDonation: `${web}dashboard/veiwDonation`,

    // expense
    expense: `${web}dashboard/expense`,
    veiwexpense: `${web}dashboard/veiwexpense`,
    //income
    income: `${web}dashboard/income`,
    //showlatefee
    showlatefee: `${web}dashboard/showlatefee`,
    //activation
        // student
    studentactivationshow: `${web}dashboard/activition/student`,
    studentActivitionInsertFee: `${web}dashboard/activition/insertfee`,
    studentActivitionUpdateActiviation: `${web}dashboard/activition/updateActiviation`,
        // teacher 
    teacheractivationshow: `${web}dashboard/activition/teacher`,
    teacherActivitionInsertFee: `${web}dashboard/activition/teacherinsertfee`,
    teacherActivitionUpdateActiviation: `${web}dashboard/activition/teacherupdateActiviation`,

    udpateStudentReg: `${web}dashboard/update/studentreg`,
    udpateTeacherReg: `${web}dashboard/update/teacherReg`,

    StudentStatus:`${web}dashboard/student/status`,
    updateClass : `${web}dashboard/student/updateStatus`
};

const Api = (state = initialState) => {
        return state;
}
export default Api;
