import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import PageContainer from '../../../components/Container/pageContainer'
import { Main } from '../../../constant'
import { Box, Grid } from '@mui/material'
import { CustomSelect } from '../../../components/select/select'
import { Date } from '../../../components/Date/Date'
import { Input } from '../../../components/input/input'
import { CustomBtn } from '../../../components/button/button'
import axios from 'axios'
import { useDispatch, useSelector } from 'react-redux'
import { SelectOnObject } from '../../../components/select/selectOnObject'
import FormTable from './components/table'
import CustomForm from '../../../components/form/form'
import { ShowLoader } from '../../../redux/actions/loader'

const StudentStatus = (props) => {
    const [ data , setData ] = useState(Main().Shoqa);
    const userToken = JSON.parse(sessionStorage.getItem('User_Data'))?.token || undefined;
    const url = useSelector((state) => state.Api);
    const [ update , setUpdate ] = useState()
    const [ serverData , setServerData ] = useState({
        ShowClass:'',
        result:''
    })
  const dispatch = useDispatch();



    const [ inputValues , setInputValues ] = useState({
        class:'',
        subject:'',
        teacher:''
    })
    useEffect(() => {
        try {
          axios.get(url.ShowClass, {
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${userToken}`,
            },
          })
          .then((res) => {
            if (res.status === 200) {
              //console.log(res.data.message);
            //   setServerData({ShowClass:res.data.result});
            setServerData(
                (oldData)=> ({
                    ...oldData,
                    ShowClass:res.data.result}
              ));
            }
          })
          .catch((err) => {
            //console.error(err); // Log the error response for debugging
          });
        } catch (err) {
          //console.error(err);
        }


        
      }, [update]);
    const ChangeOnSelect = async(getparam) => {
      dispatch(ShowLoader('1'))
    
      const payload = {
        Class:getparam[0]
      }
      try {
        axios.post(url.StudentStatus, payload, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${userToken}`,
          },
        })
        .then((res) => {
          if (res.status === 200) {
            console.log(res)
            //console.log(res.data.message);
          //   setServerData({ShowClass:res.data.result});
          setTimeout(()=>{
            dispatch(ShowLoader('0'))
          },1000)

          setServerData(
              (oldData)=> ({
                  ...oldData,
                  result:res.data.result}
            ));
          }
        })
        .catch((err) => {
          dispatch(ShowLoader('0'))
          //console.error(err); // Log the error response for debugging
        });
      } catch (err) {
        //console.error(err);
      }
    }
  return (
    <PageContainer title={data.title} description={data.description}>
        <Box>
            <Grid container>
                {
                    serverData.ShowClass && (
                        <Grid item lg={6}>
                            <Box p={1}>
                                <SelectOnObject option='ClassName' items={serverData.ShowClass} GetSelectedValue={(e)=>ChangeOnSelect(e)} name='Class' />
                            </Box>
                        </Grid>
                    )
                }
            </Grid>
            <Grid container>
                <Grid item lg={12}>
                    {/* <Box mt={4}> */}
                    {
                      serverData.result && serverData.result.length !== 0 && serverData.result != null && serverData.result != undefined && (
                        <>
                          <FormTable data={serverData.result} />
                        </>
                      )
                    }
                    {/* </Box> */}
                </Grid>
            </Grid>
        </Box>
    </PageContainer>
  )
}

StudentStatus.propTypes = {}

export default StudentStatus