import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import { Input } from '../../../../components/input/input';
import { CustomBtn } from '../../../../components/button/button';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { editSbuject } from '../../../../redux/actions/subject/editsubject';
import { deleteSubject } from '../../../../redux/actions/subject/deletesubject';

const UpdateForm = (props) => {
    const [inputValues, setInputValues] = useState(props.data);
    const url = useSelector((state)=>state.Api)
    const dispatch = useDispatch()
    const handleInputChange = (e) => {
        // console.log(e.target.value)
        setInputValues((prevValues) => ({
            ...prevValues,
            [e.target.name]: e.target.value,
        }));
    };

    const myfunc = (event) => {
        event.preventDefault();
        console.log(inputValues);
    };
    const edit =  async() => {
        console.log('clicked on edit')
      const userToken = JSON.parse(sessionStorage.getItem('User_Data')).token;
        await dispatch(editSbuject(url.UpdateSubject , userToken , inputValues))
        props.func();
      };
      
      const deletesub =  async() => {
        console.log('clicked on edit')
      const userToken = JSON.parse(sessionStorage.getItem('User_Data')).token;
        await dispatch(deleteSubject(url.DeleteSubject , userToken , inputValues))
        props.func();
      };
    return (
        <>
            <form onSubmit={myfunc}>
                <Grid container mt={2}>
                    <Grid item lg={2}>
                        <Input
                            onChange={(e) => handleInputChange(e)}
                            name="SubjectName"
                            value={inputValues.SubjectName}
                        />
                    </Grid>
                    <Grid item lg={2}>
                        <Input
                            onChange={(e) => handleInputChange(e)}
                            name="SubjectNamePashot"
                            value={inputValues.SubjectNamePashot}
                        />
                    </Grid>
                    <Grid item lg={2}>
                        <Input
                            type="number"
                            onChange={(e) => handleInputChange(e)}
                            name="Half_Year_Passing"
                            value={inputValues.Half_Year_Passing}
                        />
                    </Grid>
                    <Grid item lg={2}>
                        <Input
                            type="number"
                            onChange={(e) => handleInputChange(e)}
                            name="Full_Year_Passing"
                            value={inputValues.Full_Year_Passing}
                        />
                    </Grid>
                    <Grid item lg={2}>
                        <Input
                            type="number"
                            onChange={(e) => handleInputChange(e)}
                            name="TotalMarks"
                            value={inputValues.TotalMarks}
                        />
                    </Grid>
                    <Grid item lg={1}>
                        <CustomBtn
                            data="Edit"
                            click={edit}
                        />
                    </Grid>
                    <Grid item lg={1}>
                        <CustomBtn 
                        data="Delete" 
                        click={deletesub}
                        />
                    </Grid>
                </Grid>
            </form>
        </>
    );
};

UpdateForm.propTypes = {};

export default UpdateForm;
