import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import PageContainer from '../../../components/Container/pageContainer'
import { Main } from '../../../constant'
import { Box, Grid } from '@mui/material'
import { CustomSelect } from '../../../components/select/select'
import { Date } from '../../../components/Date/Date'
import { Input } from '../../../components/input/input'
import { CustomBtn } from '../../../components/button/button'
import axios from 'axios'
import { useSelector } from 'react-redux'
import { SelectOnObject } from '../../../components/select/selectOnObject'
import FormTable from './components/table'

const Shoqa = (props) => {
    const [ data , setData ] = useState(Main().Shoqa);
    const userToken = JSON.parse(sessionStorage.getItem('User_Data'))?.token || undefined;
    const url = useSelector((state) => state.Api);
    const [ update , setUpdate ] = useState()
    const [ serverData , setServerData ] = useState({
        ShowClass:'',

    })


    const [ inputValues , setInputValues ] = useState({
        class:'',
        subject:'',
        teacher:''
    })
    useEffect(() => {
        try {
          axios.get(url.ShowClass, {
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${userToken}`,
            },
          })
          .then((res) => {
            if (res.status === 200) {
              //console.log(res.data.message);
            //   setServerData({ShowClass:res.data.result});
            setServerData(
                (oldData)=> ({
                    ...oldData,
                    ShowClass:res.data.result}
              ));
            }
          })
          .catch((err) => {
            //console.error(err); // Log the error response for debugging
          });
        } catch (err) {
          //console.error(err);
        }


        try {
            axios.get(url.teachersuggestion, {
              headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${userToken}`,
              },
            })
            .then((res) => {
              if (res.status === 200) {
                //console.log(res.data.message);
              //   setServerData({ShowClass:res.data.result});
              setServerData(
                  (oldData)=> ({
                      ...oldData,
                      teachersuggestion:res.data.message}
                ));
              }
            })
            .catch((err) => {
              //console.error(err); // Log the error response for debugging
            });
          } catch (err) {
            //console.error(err);
          }

        
      }, [update]);
      const showSubject = (param) => {
            try {
              axios.get(`${url.ShowSubject}/${param}`, {
                headers: {
                  'Content-Type': 'application/json',
                  'Authorization': `Bearer ${userToken}`,
                },
              })
              .then((res) => {
                if (res.status === 200) {
                  //console.log(res.data.message);
                  setServerData(
                    (oldData)=> ({
                        ...oldData,
                        showSubject:res.data.message}
                  ));
                }
              })
              .catch((err) => {
                //console.error(err); // Log the error response for debugging
              });
            } catch (err) {
              //console.error(err);
            }
      }
      const ShowResult = async(param) => {
        try {
          // const payload = {SubjectSrn:inputValues.SubjectSrn != ''? inputValues.SubjectSrn : param, Term: inputValues.Term != '' ? inputValues.Term : param}
          const payload = {
            SubjectSrn:param[0] != 'SubjectSrn'? inputValues.SubjectSrn : param[1],
           Term: param[0] != 'Term' ? inputValues.Term : param[1]
          }
          //console.log(payload)
          await axios.post(url.showShoqa,payload, {
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${userToken}`,
            },
          })
          .then((res) => {
            if (res.status === 200) {
              //console.log(res.data.message);
              setServerData(
                (oldData)=> ({
                    ...oldData,
                    Result:res.data.result}
              ));
            }
          })
          .catch((err) => {
            //console.error(err); // Log the error response for debugging
          });
        } catch (err) {
          //console.error(err);
        }
  }
    const ChangeOnSelect = async(getparam) => {
      //console.log(getparam[1],getparam[0])
      const data = getparam[0] == 'Mid Term' ? 'Mid' : 'Final'
      if(getparam[1] == 'Term'){
        setInputValues((oldData)=> ({
          ...oldData,
          [getparam[1]]:data
      })
      )
      }
      else{
        setInputValues((oldData)=> ({
            ...oldData,
            [getparam[1]]:getparam[0]
        })
        )

      }

        if(getparam[1] == 'class'){
            await showSubject(getparam[0])
        }
        if(getparam[1] == 'Term'){
          await ShowResult(['Term',data])
        }
        if(getparam[1] == 'SubjectSrn'){
          //console.log(getparam)
          await ShowResult(['SubjectSrn',getparam[0]])
        }
    }
  return (
    <PageContainer title={data.title} description={data.description}>
        <Box>
            <Grid container>
                {
                    serverData.ShowClass && (
                        <Grid item lg={6}>
                            <Box p={1}>
                                <SelectOnObject option='ClassName' items={serverData.ShowClass} GetSelectedValue={(e)=>ChangeOnSelect(e)} name='class' />
                            </Box>
                        </Grid>
                    )
                }
                {
                    serverData.ShowClass && (
                        <Grid item lg={6}>
                            <Box p={1}>
                                <SelectOnObject option="SubjectName" items={serverData.showSubject} GetSelectedValue={(e)=>ChangeOnSelect(e)} name='SubjectSrn' />
                            </Box>
                        </Grid>
                    )

                }
                {
                    serverData.teachersuggestion && (
                        <Grid item lg={6}>
                            <Box p={1}>
                                <SelectOnObject option="name" items={serverData.teachersuggestion}  GetSelectedValue={(e)=>ChangeOnSelect(e)} name='teacher' />
                            </Box>
                        </Grid>
                    )
                }
                <Grid item lg={6}>
                            <Box p={1}>
                                <CustomSelect option="name" items={['Mid Term','Final Term']}  GetSelectedValue={(e)=>ChangeOnSelect(e)} name='Term' />
                            </Box>
                        </Grid>
                <Grid item lg={6}>
                    <Box p={1}>
                        <Date sx={{width: '100%'}} />
                    </Box>
                </Grid>
            </Grid>
            <Grid container>
                <Grid item lg={12}>
                    {/* <Box mt={4}> */}
                    {
                      serverData.Result && (
                        <>
                          <FormTable data={serverData.Result} />
                        </>
                      )
                    }
                    {/* </Box> */}
                </Grid>
            </Grid>
        </Box>
    </PageContainer>
  )
}

Shoqa.propTypes = {}

export default Shoqa