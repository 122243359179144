import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useTheme } from '@emotion/react';
import { Box } from '@mui/material';
import { MainClass } from '../../../../components/select/classs';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { ShowLoader } from '../../../../redux/actions/loader';
import { CustomSelect } from '../../../../components/select/select';
import { CustomBtn } from '../../../../components/button/button';


export default function FormTable(props) {
  const theme = useTheme();
  const style = theme.palette;
  const tableStyle = theme.palette.Main.tableStyle;


  const [classes , setClasses] = React.useState(); // Fetch classes outside the component
  const url = useSelector((state) => state.Api.updateClass);
  const dispatch = useDispatch();
  const [inputValues, setInputValues] = React.useState({
    RollNum: props.data[0].RollNum,
    Grade:'',
    ClassID: ''
  });

  // console.log(style.primary.light)


  const ChangeOnSelect = (getparam) => {
    console.log(getparam[1])
    if(getparam[1] == 'Class'){
      setInputValues((oldData)=> ({
          ...oldData,
          ClassID:getparam[0]
      })
      )
    }
    else{
        setInputValues((oldData)=> ({
            ...oldData,
            [getparam[1]]:getparam[0]
        })
        )
    }
}


      const myfunc = async(event)=>{
        event.preventDefault();
          dispatch(ShowLoader('1'))
          const userToken =
          JSON.parse(sessionStorage.getItem('User_Data'))?.token || undefined;
          const res = await axios.post(url,inputValues, {
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${userToken}`
            }
          });
          console.log(res)
          if (res.status === 200) {
            setClasses(res.data.result)
              dispatch(ShowLoader('0'))
          }
          console.log(inputValues)
        }


  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="caption table">
        <TableHead>
          <TableRow  sx={{background: style.primary.light}} id="thead">
          {props.data &&
            Object.keys(props.data[0]).map((key) => (
              // key !== 'subjects' ? (
                key !== 'subjects' && key !== 'action' && key !== 'Grades' && key !== 'SelectGrade' ? (
                <TableCell key={key}>{key}</TableCell>
              ) : (
                <React.Fragment key={key}></React.Fragment>
              )
            ))
          }
          {props.data &&
          props.data[0].subjects.map((key) => (
              <TableCell key={key}>{key.SubjectName}</TableCell>
          ))
        }
              <TableCell>Action</TableCell>


          </TableRow>
        </TableHead>
        <TableBody>
          {props.data &&
            props.data.map((row, ind) => (
                <TableRow key={ind}>
                    
                        {row && (
                            <>
                              {Object.keys(row).map((key, ind) => (
                                key !== 'subjects' && key !== 'action' && key !== 'Grades' && key !== 'SelectGrade' ? (
                                // key !== 'subjects' || key != 'action' || key != 'Grades' ? (
                                  <TableCell key={ind}>
                                    {typeof row[key] === 'object' ? JSON.stringify(row[key]) : row[key]}
                                  </TableCell>
                                ) : (
                                  <React.Fragment key={ind}></React.Fragment>
                                )
                              ))}
                            </>
                          )}



                          
                              {row.subjects.map((row , ind)=>(
                                 <TableCell key={ind}>

                                 {/* Object.values(row).map((value, index) => (
                                 <TableCell key={index}>{value}</TableCell>
                                 )) */}
                                

                                  {
                                  row.Total != undefined || row.Total != null ?
                                  row.Total
                                  :
                                  0
                                }
                                
                                </TableCell>
                                )
                              )
                                }
                                  <TableCell>
                                {
                                  row.action == true ? (
                                    <>
                                    <form onSubmit={myfunc}>
                                        <MainClass  required={true} name="Class" GetSelectedValue={(e)=>ChangeOnSelect(e)} />
                                        <br />
                                        <CustomSelect  required={true} name="Grade" GetSelectedValue={(e)=>ChangeOnSelect(e)} items={row.SelectGrade} />
                                        <br />
                                        <CustomBtn data='submit' />
                                    </form>
                                    </>
                                  )
                                  :
                                  <>
                                    Full Fill Marks
                                  </>
                                }
                                </TableCell>
                </TableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
