import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Table, TableBody, TableCell, TableRow } from '@mui/material'
import { Input } from '../../../../components/input/input'
import { useDispatch, useSelector } from 'react-redux'
import axios from 'axios'
import { CustomBtn } from '../../../../components/button/button'
import { useTheme } from '@emotion/react'
import { PostRequest } from '../../../../redux/actions/PostRequest'

// ... (imports and other code)

const Column = (props) => {
    const theme = useTheme();
    const [ data , setData] = useState(props.row)
    const style = theme.palette;
    const tableStyle = theme.palette.Main.tableStyle;
    const url = useSelector((state) => state.Api);
    const userToken = JSON.parse(sessionStorage.getItem('User_Data'))?.token || undefined;
    const [serverData , setServerData] = useState();
    const dispatch = useDispatch()
    console.log(props)
    const [ inputValues , setInputValues ] = useState({})
    const [update , setUpdate ] = useState(0)

    const myfunc = async(event) =>{
        event.preventDefault();
        const payload = {
            StduentRoll: data['Roll Num'],
            SubjectRoll: data['SubjectSrn'],
            TermSrn: data['Term'],
            TeacherRoll: data['TeacherRoll'],
            ...inputValues
        };
        await dispatch(PostRequest(url.addShoqa ,userToken, payload ))
        setUpdate((state)=>state+1)
    }
    const changeFunc = (event) =>{
        setInputValues((oldData)=> ({
            ...oldData,
            [event.target.name]:event.target.value
        })
        )

    }
    return (
        <TableCell colSpan={11} sx={tableStyle}>
                {
                    props.row.Total ? 
                        <Table>
                            <TableBody id="multiRows">
                                <TableRow>
                                <React.Fragment>
                                    <TableCell>{props.row['Roll Num']}</TableCell>
                                    <TableCell>{props.row['Name']}</TableCell>
                                    <TableCell>{props.row['Subject Name Pashto']}</TableCell>
                                    <TableCell>{props.row['Subject Name']}</TableCell>
                                    <TableCell>{props.row['Total Marks']}</TableCell>
                                    <TableCell>{props.row.Written}</TableCell>
                                    <TableCell>{props.row.Oral}</TableCell>
                                    <TableCell>{props.row.HomeWork}</TableCell>
                                    <TableCell>{props.row.Activity}</TableCell>
                                    <TableCell>{props.row['Passing Marks']}</TableCell>
                                    <TableCell>
                                        <CustomBtn data="Submit" />
                                    </TableCell>
                                </React.Fragment>
                                </TableRow>
                            </TableBody>
                        </Table>
                    
                    :
                    
                        update == 1 ?
                        <Table>
                            <TableBody id="multiRows">
                                <TableRow>
                                <React.Fragment>
                                    <TableCell>{props.row['Roll Num']}</TableCell>
                                    <TableCell>{props.row['Name']}</TableCell>
                                    <TableCell>{props.row['Subject Name Pashto']}</TableCell>
                                    <TableCell>{props.row['Subject Name']}</TableCell>
                                    <TableCell>{props.row['Total Marks']}</TableCell>
                                    <TableCell>{inputValues.Written}</TableCell>
                                    <TableCell>{inputValues.Oral}</TableCell>
                                    <TableCell>{inputValues.HomeWork}</TableCell>
                                    <TableCell>{inputValues.Activity}</TableCell>
                                    <TableCell>{props.row['Passing Marks']}</TableCell>
                                    <TableCell>
                                        <CustomBtn data="Submit" />
                                    </TableCell>
                                </React.Fragment>
                                </TableRow>
                            </TableBody>
                        </Table>
                    :
                    <form style={{ width: '100%' }} onSubmit={myfunc}>
                        <Table>
                                <TableBody id="multiRows">
                                    <TableRow>
                                    <React.Fragment>
                                        <TableCell>{props.row['Roll Num']}</TableCell>
                                        <TableCell>{props.row['Name']}</TableCell>
                                        <TableCell>{props.row['Subject Name Pashto']}</TableCell>
                                        <TableCell>{props.row['Subject Name']}</TableCell>
                                        <TableCell>{props.row['Total Marks']}</TableCell>
                                        <TableCell><Input name="Written" sx={{maxHeight:'40px'}} onChange={changeFunc} placeholder="Written" type='number' required /></TableCell>
                                        <TableCell><Input name="Oral" sx={{maxHeight:'40px'}} onChange={changeFunc} placeholder="Oral" type='number' required /></TableCell>
                                        <TableCell><Input name="HomeWork" sx={{maxHeight:'40px'}} onChange={changeFunc} placeholder="Home Work" type='number' required /></TableCell>
                                        <TableCell><Input name="Activity" sx={{maxHeight:'40px'}} onChange={changeFunc} placeholder="Activity" type='number' required /></TableCell>
                                        <TableCell>{props.row['Passing Marks']}</TableCell>
                                        <TableCell>
                                            <CustomBtn data="Submit" />
                                        </TableCell>
                                    </React.Fragment>
                                    </TableRow>
                                </TableBody>
                            </Table>
                    </form>
                    
                }
        </TableCell>
    );
};

Column.propTypes = {};

export default Column;
