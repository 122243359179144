import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useTheme } from '@emotion/react';
import { Box, Card, Grid, Stack } from '@mui/material';
import PageContainer from '../../../components/Container/pageContainer';
import Logo from '../../../components/Logo/Logo';
import { CustomBtn } from '../../../components/button/button';
import { UserInterFace } from '../../../constant/Website';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Link } from 'react-router-dom';
import Alkhidmat from './mainPages/alkhidmat';

const Contact = (props) => {
    const [ data , setData ] = useState(UserInterFace.Contact);
    const theme = useTheme();
    const style = theme.palette.Main.contact;
    console.log('this page is loading');
    const item ='/auth/'
  return (
    <PageContainer  title="Home" description="Home Page">

        <Box  sx={style.mainbox}>
                <Grid container spacing={0} justifyContent="center" sx={{ minHeight: '100vh' }}>
           
                <Grid item
                    xs={12}
                    sm={12}
                    lg={4}
                    xl={3}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                >
                    <Card elevation={9} sx={{ p: 4, zIndex: 1, width: '100%', width: '700px' }}>
                    <Box display="flex" alignItems="center" justifyContent="center">
                        <Logo />
                    </Box>
                    <Box elevation={9} sx={{ p: 2, zIndex: 1, width: '100%', width: '450px' }}>
                        <p>This is a dummy site powered by XaoSoft Technologies</p>
                    </Box>
                        {/* <a href='/auth/login' >welcome</a> */}

                        <CustomBtn link='/auth/login' data='Test Login' />
                    </Card>
                    </Grid>
                </Grid>
            </Box>
    </PageContainer>
  )
}

Contact.propTypes = {}

export default Contact