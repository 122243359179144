import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { MainClass } from '../../../../components/select/classs'
import Selectclass from './selectclass'
import axios from 'axios'
import { useDispatch, useSelector } from 'react-redux'
import CustomTable from '../../../../components/table/SimpleTable'
import { ShowLoader } from '../../../../redux/actions/loader'

const ClassunderStudent = (props) => {
    const [currentClass , setCurrentClass] = useState('')
    const [classes , setClasses] = React.useState(); // Fetch classes outside the component
    const url = useSelector((state) => state.Api.showClassStudents);
    const dispatch = useDispatch();


    const CurrentClass =(getparam) =>{
       console.log(getparam)
       setCurrentClass(getparam)
   }
//    const func = () => {
    
//    }
   const ChangeOnSelect = (getparam) => {
    dispatch(ShowLoader('1'))
    console.log(getparam[1],getparam[0])
        // axios.get()
        const myfunc = async()=>{
            const userToken =
            JSON.parse(sessionStorage.getItem('User_Data'))?.token || undefined;
            const res = await axios.get(`${url}${getparam[0]}`, {
              headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${userToken}`
              }
            });
            console.log(res)
            if (res.status === 200) {
              setClasses(res.data.result)
                dispatch(ShowLoader('0'))
            }
          }
          myfunc()
    }
  return (
    <>
        <MainClass GetSelectedValue={(e)=>ChangeOnSelect(e)} />
        <br />
        {
            classes && classes.length != 0 && classes != null && classes != undefined  ? (
            // classes != [] && classes.length != 0 && classes != null ? (
                <>
                <CustomTable
                    data={classes}
                />
                <h2>Total Students {classes.length}</h2>
                </>
            )
            :
            null
        }
    </>
  )
}

ClassunderStudent.propTypes = {}

export default ClassunderStudent